import { Link } from "react-router-dom";
import PageWrapper from "./PageWrapper";

const Etc = () => (
  <PageWrapper>
    <h1>/etc</h1>
    <h2>Thanks</h2>
    <p>
      Thanks for visiting. I hope you enjoyed your stay.
    </p>
    <h2>Contact</h2>
    <p>
      I'm most responsive through Discord, you can add me: car#0008
    </p>
    <h2>Other</h2>
    <ul>
      <li>
        <Link to={"/presence"}>/presence</Link>
      </li>
      <li>
        <a href={"https://maddex.co"}>maddex.co</a>
      </li>
    </ul>
  </PageWrapper>
);

export default Etc;
