import PageWrapper from "./PageWrapper";
import Co from "../components/Co";
import styled from "styled-components";
import HivenAppIcon from '../assets/images/hiven-app-icon.png';
import GigglAppIcon from '../assets/images/giggl-app-icon.jpg';
import HonkAppIcon from '../assets/images/honk-app-icon.jpg';
import MultiorbAppIcon from '../assets/images/multiorb-app-icon.jpg';
import MethodAppIcon from '../assets/images/method-app-icon.jpg';
import SouthendAppIcon from '../assets/images/southend-app-icon.png';
import Repo from "../components/Repo";

const Where = () => {
  return (
    <PageWrapper>
       <h1>Where I've Done It</h1>
       <h3>Companies</h3>
      <CoWrapper>
        <Co url="https://method-it.co.uk" name="Method-IT" iconReference={MethodAppIcon} tagline="Managed Service Provider" role={"IT Analyst"} what={"A strategic approach to IT Support providing 24/7/365 IT Support Services in Essex & the South East."}/>
        <Co url="" name="Multiorb" iconReference={MultiorbAppIcon} tagline="Development" role={"Founder & Developer"} what={"Multiorb is a software development and project management project that specializes in creating technical solutions for our open-source partners."}/>
        <Co url="https://www.mse.nhs.uk/southend-hospital/" name="Southend University Hospital NHS" iconReference={SouthendAppIcon} tagline="Health Care" role={"IT Analyst"} what={"Health care institution providing patient treatment with specialized medical and nursing staff and medical equipment."}/>
      </CoWrapper>

      <h3>Open-source Projects</h3>
      <Repo name={"eRequest"} url={"https://github.com/name/erequest"} primaryLanguage={"C# & PHP"} description=".NET/PHP library to allow encrypted json data to be transferred between client and server."/>
    </PageWrapper>
  )
}

const CoWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export default Where;