import PageWrapper from "./PageWrapper";

const Home = () => {
  return (
    <PageWrapper forceReadableWidth>
      <h1>What I Do</h1>
      <p>19 y/o developer and investor.</p>
      <p>
        I design, build and publish products of quality and reliability.
        Currently, I'm a IT Analyst full time at{" "}
        <a href="https://method-it.co.uk" target="norel noopen">
          Method-IT
        </a>{" "}.
      </p>

      <p>
        In my free time, I work on various side-projects; I'm currently learning
        Elixir and working on internal infrastructure. I also invest in stocks and cryptocurrency. Got a
        interest in getting started? I'm always down to chat to new investors.
      </p>

      <h3>Some Articles</h3>
      <p>
        {" "}
        <a href="https://medium.com/@charliemaddex/using-optical-character-recognition-to-verify-failed-server-screenshots-3215c9e73897" target="norel noopen">
          Using Optical Character Recognition to verify failed server screenshots .
        </a>{" "}.
      </p>
    </PageWrapper>
  );
};

export default Home;
